import Layout from "../components/layout"
import SEO from "../components/seo"
import React from "react"
import TextPageComponent from "../components/shared/text-page"

const PolicyPage = () => (
  <Layout lang={"en"}
          forceFixedHeader={true}
          page={"policy"}>
    <SEO title="Privacy Policy" noindex="noindex"/>
    <TextPageComponent title={"Privacy Policy"}>
      <p>
        Welcome to Haulmont Technology Limited's privacy notice.
      </p>
      <p>
        Haulmont Technology Limited respects your privacy and is committed to protecting your personal data. This
        privacy notice will inform you as to how we look after your personal data when you visit our website (regardless
        of where you visit it from) and tell you about your privacy rights and how the law protects you.
      </p>
      <ol className={"ordered-list"}>
        <li>
          <h3>Important information and who we are</h3>
          <h4>Purpose of this privacy notice</h4>
          <p>
            This privacy notice aims to give you information on how Haulmont Technology Limited collects and processes
            your personal data through your use of this website, including any data you may provide through this
            website.
          </p>
          <p>
            It is important that you read this privacy notice together with any other privacy notice or fair processing
            notice we may provide on specific occasions when we are collecting or processing personal data about you so
            that you are fully aware of how and why we are using your data. This privacy notice supplements the other
            notices and is not intended to override them.
          </p>
          <h4>Controller</h4>
          <p>Haulmont Technology Limited is the controller and responsible for your personal data (collectively referred
            to as "Haulmont", "CUBA Platform", "we", "us" or "our" in this privacy notice).</p>
          <p>We have appointed a data privacy manager who is responsible for overseeing questions in relation to this
            privacy notice. If you have any questions about this privacy notice, including any requests to exercise your
            legal rights, please contact the data privacy manager using the details set out below.</p>
          <h4>Contact details</h4>
          <p>Our full details are:</p>
          <ul>
            <li>Full name of legal entity: Haulmont Technology Limited</li>
            <li>Name of data privacy manager: Alistair Laycock</li>
            <li>Email address: info@paraplancrm.ru</li>
            <li>Postal address: Blick Rothenberg Limited 1st Floor, 7-10 Chandos Street, London, United Kingdom,
              W1G9DQ
            </li>
            <li>Telephone number: +44 (0)20 3214 5110</li>
          </ul>
          <p>You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK
            supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance
            to deal with your concerns before you approach the ICO so please contact us in the first instance.</p>
          <h4>Changes to the privacy notice and your duty to inform us of changes</h4>
          <p>This version was last updated on September 2019.</p>
          <p>It is important that the personal data we hold about you is accurate and current. Please keep us informed
            if your personal data changes during your relationship with us.</p>
          <h4>Third-party links</h4>
          <p>This website may include links to third-party websites, plug-ins and applications. Clicking on those links
            or enabling those connections may allow third parties to collect or share data about you. We do not control
            these third-party websites and are not responsible for their privacy statements. When you leave our website,
            we encourage you to read the privacy notice of every website you visit.</p>
        </li>
        <li>
          <h3>The data we collect about you</h3>
          <p>Personal data, or personal information, means any information about an individual from which that person
            can be identified. It does not include data where the identity has been removed (anonymous data).</p>
          <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped
            together follows:</p>
          <ul>
            <li>
              <b>Identity Data</b> includes first name, last name, username or similar identifier, title, photo.
            </li>
            <li>
              <b>Contact Data</b> includes billing address, delivery address, email address.
            </li>
            <li>
              <b>Financial Data</b> includes bank account and payment card details.
            </li>
            <li>
              <b>Transaction Data</b> includes details about payments to and from you and other details of products
              and services you have purchased from us.
            </li>
            <li>
              <b>Technical Data</b> includes internet protocol (IP) address, your login data, browser type and version,
              time zone setting and location, browser plug-in types and versions, operating system and platform and
              other technology on the devices you use to access this website.
            </li>
            <li>
              <b>Profile Data</b> includes your username and password, purchases or orders made by you, your interests,
              preferences, feedback and survey responses.
            </li>
            <li>
              <b>Usage Data</b> includes information about how you use our website, products and services.
            </li>
            <li>
              <b>Marketing and Communications Data</b> includes your preferences in receiving marketing from us and
              our third parties and your communication preferences.
            </li>
          </ul>
          <p>We also collect, use and share <b>Aggregated Data</b> such as statistical or demographic data for any
            purpose. Aggregated Data may be derived from your personal data but is not considered personal data in law
            as this data does <b>not</b> directly or indirectly reveal your identity. For example, we may aggregate your
            Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine
            or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we
            treat the combined data as personal data which will be used in accordance with this privacy notice.</p>
          <p>We do not collect any <b>Special Categories of Personal Data</b> about you (this includes details about
            your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political
            opinions, trade union membership, information about your health and genetic and biometric data). Nor do we
            collect any information about criminal convictions and offences.</p>
          <h4>If you fail to provide personal data</h4>
          <p>Where we need to collect personal data by law, or under the terms of a contract we have with you and you
            fail to provide that data when requested, we may not be able to perform the contract we have or are trying
            to enter into with you (for example, to provide you with goods or services). In this case, we may have to
            cancel a product or service you have with us but we will notify you if this is the case at the time.</p>
        </li>
      </ol>
    </TextPageComponent>
  </Layout>
)

export default PolicyPage
